
import { formatMoney, logout } from "@/plugins/GlobalFunctions";
import axios from "axios";
import Swal from "sweetalert2";
import Vue from "vue";
import Multiselect from "vue-multiselect";

declare interface MarketingData {
  username: string;
  ref_code: string;
}
declare interface MainData {
  username: string;
  password: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  marketing: MarketingData | null;
  marketingsList: Array<MarketingData> | [];

  submit: boolean;
  show: boolean;
}

export default Vue.extend({
  name: "ModalMemberComponent",
  created() {
    this.$root.$on("modal-member-show", () => {
      this.open();
    });
  },
  components: {
    Multiselect,
  },
  computed: {
    validate() {
      if (
        !this.username ||
        !this.password ||
        !this.first_name ||
        !this.last_name
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  data(): MainData {
    return {
      username: "",
      password: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      marketing: null,
      marketingsList: [],

      submit: false,
      show: false,
    };
  },
  methods: {
    open() {
      this.show = true;
      this.username = "";
      this.password = "";
      this.first_name = "";
      this.last_name = "";
      this.phone_number = "";
      this.marketing = null;
      this.getMarketingsLists();
    },
    close() {
      this.show = false;
      this.submit = false;
    },
    async getMarketingsLists() {
      await axios
        .get("/api/user/marketings/list", {
          headers: this.$store.state.headers,
          params: {
            is_active: true,
          },
        })
        .then((resp) => {
          this.marketingsList = resp.data.data.items || [];
        })
        .catch((error) => {
          this.$store.dispatch("swal", error);
        });
    },
    async onSubmit() {
      if (this.phone_number && this.phone_number.length != 10) {
        return Swal.fire({
          icon: "warning",
          title: "Phone number may be invalid",
          text: "Valid phone numbers are between 7 and 10 digits long",
        });
      }
      this.submit = true;
      let formData = {
        username: this.username,
        password: this.password,
        password_confirmation: this.password,
        first_name: this.first_name,
        last_name: this.last_name,
        phone_number: this.phone_number ? this.phone_number : "0000000000",
        blocked_game_type: [],
        mkt_code: (this.marketing && this.marketing.ref_code) || null,
      };
      await axios
        .post("/api/client/members/v2/register", formData, {
          headers: this.$store.state.headers,
        })
        .then(() => {
          this.$emit("reload");
        })
        .catch((error) => {
          this.$store.dispatch("swal", error);
        })
        .finally(() => {
          this.close();
        });
    },

    formatMoney,
    logout,
  },
});
