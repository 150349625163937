function formatMoney(amount: number, decimalCount: number) {
  return `${amount.toLocaleString(undefined, {
    minimumFractionDigits: decimalCount,
    maximumFractionDigits: decimalCount,
  })}`;
}
function userStatusVariant(status: number) {
  switch (status) {
    case 1: // band
      return "danger";
    case 2: //"Normal"
      return "success";
    case 3: // band
      return "danger";
    default:
      return "secondary";
  }
}
function dateToUnits(value: Date) {
  const time = new Date(value).getTime() / 1000;
  return time - (time % 60);
}
function dateTimeFormats(datetime: number) {
  if (datetime < 0 || datetime == null) {
    return "-";
  }
  if (String(datetime).length <= 10) datetime *= 1000;
  if (String(datetime).length > 13) datetime /= 1000000;
  const newDate = new Date(datetime).toLocaleString("en-GB");
  const date = newDate.split(",")[0];

  const time = newDate.split(",")[1];

  return date + " " + time;
}
function transaction_type(key: string) {
  switch (key) {
    case "transfer":
      return '<span class="badge badge-warning" >Transfer</span>';
    case "game":
      return '<span class="badge badge-info" >Play</span>';
    case "coupon":
      return '<span class="badge badge-success">Coupon</span>';
    case "checkin":
      return '<span class="badge badge-primary" >Checkin</span>';
    case "refund":
      return '<span class="badge badge-dark" >Refund</span>';
    case "cutover":
      return '<span class="badge badge-danger">Cutover</span>';
    case "bank":
      return '<span class="badge badge-success" >Bank</span>';
    default:
      return '<span class="badge badge-secondary" >' + key + "</span>";
  }
}
function transaction_subtype(key: string) {
  switch (key) {
    case "deposit":
      return '<span class="badge badge-success" >Deposit</span>';
    case "widthdraw":
      return '<span class="badge badge-danger" >Widthdraw</span>';
    case "up":
      return '<span class="badge badge-success" >Up</span>';
    case "down":
      return '<span class="badge badge-danger" >Down</span>';
    default:
      return '<span class="badge badge-secondary" >' + key + "</span>";
  }
}
function number_color(amount: number | string) {
  if (amount == 0) return "";
  else if (amount > 0) return "text-success";
  else return "text-danger";
}
function logout() {
  localStorage.removeItem("access_token");
  localStorage.removeItem("user_type");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("refresh_expires_in");
  localStorage.removeItem("username");
  location.reload();
}

export {
  formatMoney,
  userStatusVariant,
  dateToUnits,
  dateTimeFormats,
  transaction_type,
  transaction_subtype,
  number_color,
  logout,
};
