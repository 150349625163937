
import DatePeriod from "@/components/DatePeriod.vue";
import GameTransactionMember from "@/components/modal/GameTransactionMember.vue";
import Pagination from "@/components/Pagination.vue";
import PaginationInterface from "@/interface/Pagination";
import MainLayout from "@/layouts/MainLayout.vue";

import {
  dateToUnits,
  formatMoney,
  logout,
  number_color,
  userStatusVariant,
} from "@/plugins/GlobalFunctions";
import axios from "axios";
import Vue from "vue";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import * as XLSX from "xlsx";

declare interface PaginateData {
  limit: number;
  page: number;
  total: number;
}
declare interface QueryData {
  dateRange: Array<Date>;
  brands: Array<any> | null;
  search: string;
  page: number;
  from: number;
  limit: number;
}
declare interface SummaryData {
  bet_amount: number;
  down_line_win_lose: number;
  marketing_value: number;
  my_win_lose: number;
  percent_share: number;
  refund_amount: number;
  up_line_win_lose: number;
  win_amount: number;
}
declare interface MainData {
  busy: boolean;
  query: QueryData;
  options_brands_group: Array<{
    group_name: string | null;
    group_values: Array<any>;
  }>;
  items:
    | Array<{
        username: string;
        no_ticket: number;
        bet_amount: number;
        down_line_win_lose: number;
        my_win_lose: number;
        up_line_win_lose: number;
      }>
    | [];
  summary: SummaryData | null;
  paginate: PaginateData | null;
}

export default Vue.extend({
  name: "GameTransactionView",
  components: {
    MainLayout,
    DatePeriod,
    Pagination,
    DatePicker,
    Multiselect,
    GameTransactionMember,
  },
  data(): MainData {
    return {
      busy: false,
      query: {
        dateRange: [],
        brands: null,
        search: "",
        page: 1,
        from: 0,
        limit: 10,
      },
      options_brands_group: [],
      items: [],
      summary: null,
      paginate: null,
    };
  },
  mounted() {
    this.setDateRange();
  },
  methods: {
    setNewDateRange(newDateRange: Array<Date>) {
      this.query.dateRange = newDateRange;
      this.getTransactionSumByUser(null);
    },
    setDateRange() {
      let start_date = [0, 0, 0];
      let end_date = [23, 59, 59];
      var curr = new Date();
      var firstday = new Date(
        curr.getFullYear(),
        curr.getMonth(),
        curr.getDate(),
        ...start_date
      );
      var lastday = new Date(
        curr.getFullYear(),
        curr.getMonth(),
        curr.getDate(),
        ...end_date
      );
      this.query.dateRange = [firstday, lastday];
      this.gameBrand();
      this.getTransactionSumByUser(null);
    },
    async gameBrand() {
      await axios
        .get("/api/game/agent/get_brand", {
          headers: this.$store.state.headers,
        })
        .then((resp) => {
          let data = (resp.data.data && resp.data.data.game_data) || [];
          data.sort((a: any, b: any) => a.sorting - b.sorting);
          this.options_brands_group = [
            {
              group_name: "-Select all-",
              group_values: data || [],
            },
          ];
        })
        .catch((error) => {
          this.$store.dispatch("swal", error);
        });
    },
    async getTransactionSumByUser(payload: PaginationInterface | null) {
      if (payload && Object.keys(payload).length > 0) {
        this.query.page = payload && payload.page;
        this.query.limit = payload && payload.limit;
      }
      this.busy = true;
      await axios
        .get("/api/report/get_transaction_sum_by_user", {
          headers: this.$store.state.headers,
          params: {
            search: this.query.search,
            from: (this.query.page - 1) * this.query.limit,
            size: this.query.limit,
            start_date: dateToUnits(this.query.dateRange[0]),
            end_date: dateToUnits(this.query.dateRange[1]),
            array_game_id:
              this.query &&
              this.query.brands &&
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              this.query.brands.map((e: any) => {
                return e.id;
              }),
          },
        })
        .then((resp) => {
          this.items = resp.data.data.transaction_sum_by_user_data || [];
          this.summary = resp.data.data.summary_data || null;
          this.paginate =
            {
              limit: resp.data.data.paginate_data.size || this.query.limit,
              page: resp.data.data.paginate_data.page || this.query.page,
              total: resp.data.data.paginate_data.total || 0,
            } || null;
        })
        .catch((error) => {
          this.$store.dispatch("swal", error);
        })
        .finally(() => {
          this.busy = false;
        });
    },
    showGameTransactionMember(username: string, dateRange: Array<Date>) {
      this.$root.$emit(
        "modal-game-transaction-member-show",
        username,
        dateRange
      );
    },
    exportToExcel() {
      let excelArrayData: any = [
        ["#", "Username", "No.Ticket", "BET", "DOWNLINE", "USER", "UPLINE"],
      ];
      for (let index = 0; index < this.items.length; index++) {
        const item = this.items[index];
        excelArrayData.push([
          index + 1,
          item.username,
          item.no_ticket,
          item.bet_amount,
          item.down_line_win_lose,
          item.my_win_lose,
          item.up_line_win_lose,
        ]);
      }
      // Create a new workbook
      const workbook = XLSX.utils.book_new();

      // Add a worksheet to the workbook
      const worksheet = XLSX.utils.aoa_to_sheet(excelArrayData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      let startDate = this.query.dateRange[0];
      let nameStart =
        startDate.getDate() +
        "_" +
        (startDate.getMonth() + 1) +
        "_" +
        startDate.getFullYear();
      let endDate = this.query.dateRange[1];
      let nameEnd =
        endDate.getDate() +
        "_" +
        (endDate.getMonth() + 1) +
        "_" +
        endDate.getFullYear();

      // Apply styles to the worksheet
      worksheet["!cols"] = [
        { width: 8 },
        { width: 20 },
        { width: 12 },
        { width: 12 },
        { width: 12 },
        { width: 12 },
        { width: 12 },
      ];

      // Generate an Excel file and download it
      XLSX.writeFile(workbook, `game_transaction_${nameStart}-${nameEnd}.xlsx`);
    },

    formatMoney,
    userStatusVariant,
    dateToUnits,
    number_color,
    logout,
  },
});
