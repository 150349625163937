
import { formatMoney, logout, number_color } from "@/plugins/GlobalFunctions";
import axios from "axios";
import Vue from "vue";

declare interface DataMA {
  end_ma: number;
  is_ma: boolean;
  is_show: boolean;
  start_ma: number;

  show: boolean;
}
declare interface MainData {
  busy: boolean;
  username: string;
  userType: string;
  balanceLists:
    | Array<{
        name: number;
        balance: number;
        currency: string;
        updated_at: number;
        wallet_id: number;
      }>
    | [];

  data_ma: DataMA | null;
  is_countdown: number;
  time_count: string | undefined | null;

  menuLists:
    | Array<{
        title: string;
        childs: Array<{
          name: string;
          to: string;
          icon: string;
        }>;
      }>
    | [];

  show: boolean;
}

export default Vue.extend({
  name: "MainLayout",
  data(): MainData {
    return {
      busy: false,
      username: localStorage.getItem("username") || "",
      userType: localStorage.getItem("user_type") || "",
      balanceLists: [],

      data_ma: null,
      is_countdown: 0,
      time_count: null,

      menuLists: [
        {
          title: "User",
          childs: [
            {
              name: "Management",
              to: "/user/management",
              icon: "account-multiple",
            },
          ],
        },
        {
          title: "Wallet",
          childs: [
            {
              name: "Transfer",
              to: "/wallet/transfer",
              icon: "cash-fast",
            },
            {
              name: "Transaction",
              to: "/wallet/Transaction",
              icon: "bank-transfer",
            },
          ],
        },
        {
          title: "Game",
          childs: [
            {
              name: "Transaction",
              to: "/game/Transaction",
              icon: "controller",
            },
          ],
        },
        {
          title: "Marketing",
          childs: [
            {
              name: "Management",
              to: "/marketing/management",
              icon: "clipboard-list",
            },
            {
              name: "Transaction",
              to: "/marketing/transaction",
              icon: "chart-areaspline",
            },
            {
              name: "Commission",
              to: "/marketing/commission",
              icon: "percent-outline",
            },
          ],
        },
      ],

      show: true,
    };
  },
  mounted() {
    this.getCheckMA();
  },
  methods: {
    async getCheckMA() {
      await axios
        .get("/api/system/ma-detail", {
          headers: this.$store.state.headers,
        })
        .then((resp) => {
          this.data_ma = resp.data.data || null;
          this.countdown();
          this.getBalance();
        })
        .catch((error) => {
          this.$store.dispatch("swal", error);
        });
    },
    countdown() {
      if (!this.is_countdown) {
        this.is_countdown = setInterval(() => {
          this.time_count = this.countdown_ma();
          this.countdown();
        }, 1000);
      }
    },
    countdown_ma() {
      try {
        if (this.data_ma && this.data_ma.is_ma) {
          let time =
            this.data_ma.end_ma - Math.floor(new Date().getTime() / 1000);
          return time >= 0 ? this.convertTime(time) : "";
        } else {
          if (this.data_ma && this.data_ma.start_ma) {
            let time =
              this.data_ma.start_ma - Math.floor(new Date().getTime() / 1000);
            return time >= 0 ? this.convertTime(time) : "";
          }
        }
      } catch {
        return "";
      }
    },
    convertTime(t: number) {
      let d = Math.floor(t / 86400);
      let h = Math.floor(t / 3600) % 24;
      let m = Math.floor(t / 60) % 60;
      let s = Math.floor(t % 60);
      return (
        (d ? d + " day. " : "") +
        (h ? h + " hour. " : "") +
        (m ? m + " mins. " : "") +
        (d || h || m || s ? s + " secons." : "")
      );
    },
    async getBalance() {
      this.busy = true;
      await axios
        .get("/api/wallet/balance", {
          headers: this.$store.state.headers,
        })
        .then((resp) => {
          this.balanceLists = resp.data.data.list || [];
        })
        .catch((error) => {
          this.$store.dispatch("swal", error);
        })
        .finally(() => {
          this.busy = false;
        });
    },
    async onLogout() {
      this.busy = true;
      setTimeout(() => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user_type");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("refresh_expires_in");
        localStorage.removeItem("username");
        location.reload();
      }, 250);
    },

    formatMoney,
    number_color,
    logout,
  },
});
