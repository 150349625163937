
import ChangePassword from "@/components/modal/ChangePassword.vue";
import EditProfile from "@/components/modal/EditProfile.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import {
  formatMoney,
  logout,
  number_color,
  userStatusVariant,
} from "@/plugins/GlobalFunctions";
import axios from "axios";
import Vue from "vue";

declare interface QueryData {
  search: string;
}
declare interface MainData {
  busy: boolean;
  query: QueryData;
  items:
    | Array<{
        username: string;
        first_name: string;
        last_name: string;
        ref_code: string;
        percent_share: number;
        total_register: number;
      }>
    | [];
}

export default Vue.extend({
  name: "MarketingManagementView",
  components: {
    MainLayout,
    ChangePassword,
    EditProfile,
  },
  data(): MainData {
    return {
      busy: false,
      query: {
        search: "",
      },
      items: [],
    };
  },
  mounted() {
    this.getMarketingsLists();
  },
  methods: {
    async getMarketingsLists() {
      this.busy = true;
      await axios
        .get("/api/user/marketings/list", {
          headers: this.$store.state.headers,
          params: {
            search: this.query.search,
            is_active: true,
          },
        })
        .then((resp) => {
          this.items = resp.data.data.items || [];
        })
        .catch((error) => {
          this.$store.dispatch("swal", error);
        })
        .finally(() => {
          this.busy = false;
        });
    },
    onChangePassword(item: any) {
      this.$root.$emit("modal-change-password", item);
    },
    onEditProfile(item: any) {
      this.$root.$emit("modal-edit-profile", item);
    },

    formatMoney,
    userStatusVariant,
    number_color,
    logout,
  },
});
