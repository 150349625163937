import Swal from "sweetalert2";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    headers: {
      Authorization: localStorage.getItem("access_token"),
      "Content-Type": "application/json",
      Accept: "application/json",
      "Allow-Control-Allow-Origin": "*",
    },
  },
  getters: {},
  mutations: {},
  actions: {
    async swal({ commit }, payload) {
      await Swal.fire({
        icon: "error",
        text: payload?.response?.data?.message?.message || "Unknow",
      });
      if (payload?.response?.data?.status?.message === "Unauthorized") {
        this.dispatch("logout");
      }
    },
    logout({ commit }, payload) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("user_type");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("refresh_expires_in");
      localStorage.removeItem("username");
      location.reload();
    },
  },
  modules: {},
});
