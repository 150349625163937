
import NoAuthLayout from "@/layouts/NoAuthLayout.vue";
import axios from "axios";
import Swal from "sweetalert2";
import Vue from "vue";

declare interface FormData {
  username: string;
  password: string;
}
declare interface MainData {
  busy: boolean;
  form: FormData;
}

export default Vue.extend({
  name: "LoginView",
  components: {
    NoAuthLayout,
  },
  data(): MainData {
    return {
      busy: false,
      form: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    async onSubmit(event: Event) {
      event.preventDefault();
      this.busy = true;
      await axios
        .post("/api/agentlogin", this.form)
        .then((resp) => {
          let { data } = resp.data || null;
          if (data.user_type === "Agent") {
            localStorage.setItem(
              "access_token",
              data.token_type + " " + data.access_token
            );
            localStorage.setItem("user_type", data.user_type);
            localStorage.setItem("refresh_token", data.refresh_token);
            localStorage.setItem("refresh_expires_in", data.refresh_expires_in);
            localStorage.setItem("username", data.username);
            localStorage.setItem("user_id", data.user_id);
            location.reload();
          } else {
            Swal.fire({
              icon: "error",
              title: "Access rights",
              text: "This user has no access rights.",
            });
          }
        })
        .catch((error) => {
          this.$store.dispatch("swal", error);
        })
        .finally(() => {
          this.busy = false;
        });
    },
  },
});
