
import DatePeriod from "@/components/DatePeriod.vue";
import Pagination from "@/components/Pagination.vue";
import PaginationInterface from "@/interface/Pagination";
import {
  dateTimeFormats,
  dateToUnits,
  formatMoney,
  logout,
  number_color,
  transaction_subtype,
  transaction_type,
} from "@/plugins/GlobalFunctions";
import axios from "axios";
import Vue from "vue";
import DatePicker from "vue2-datepicker";

declare interface PaginateData {
  limit: number;
  page: number;
  total: number;
}
declare interface QueryData {
  dateRange: Array<Date>;
  search: string;
  page: number;
  from: number;
  limit: number;
}
declare interface SummaryData {
  deposit: SummaryDetail;
  withdraw: SummaryDetail;
}
declare interface SummaryDetail {
  avg_amount: SummaryObject;
  doc_count: number;
  max_amount: SummaryObject;
  min_amount: SummaryObject;
  sum_amount: SummaryObject;
}
declare interface SummaryObject {
  value: number;
}
declare interface MainData {
  busy: boolean;
  query: QueryData;
  wallet_id: number;
  type: string | null;
  types: Array<{
    text: string;
    value: string | null;
  }>;
  summary: SummaryData | null;
  items: Array<{
    amount: number;
    amount_after: number;
    amount_before: number;
    amount_bonus: number;
    asset_name: string;
    asset_unit: string;
    created_at: number;
    first_name: string;
    last_name: string;
    marketing_code: string;
    member_ref_code: string;
    note: string;
    parent_username: string;
    phone_number: string;
    ref_code: string;
    type_name: string;
    type_sub_name: string;
    username: string;
  }>;
  paginate: PaginateData | null;

  show: boolean;
}

export default Vue.extend({
  name: "ModalWalletTransactionMemberComponent",
  created() {
    this.$root.$on(
      "modal-wallet-transaction-member-show",
      (username: string, dateRange: Array<Date>) => {
        this.open(username, dateRange);
      }
    );
  },
  components: {
    DatePicker,
    DatePeriod,
    Pagination,
  },
  data(): MainData {
    return {
      busy: false,
      query: {
        dateRange: [],
        search: "",
        page: 1,
        from: 0,
        limit: 10,
      },
      wallet_id: 1001,
      type: null,
      types: [
        { text: "All Type", value: null },
        { text: "Transfer", value: "transfer" },
        { text: "Game", value: "game" },
        { text: "Refund", value: "refund" },
        { text: "Bank", value: "bank" },
      ],
      items: [],
      summary: null,
      paginate: null,

      show: false,
    };
  },
  methods: {
    setNewDateRange(newDateRange: Array<Date>) {
      this.query.dateRange = newDateRange;
      this.getTransaction(null);
    },
    open(username: string, dateRange: Array<Date>) {
      this.query.search = username;
      this.query.dateRange = dateRange;
      this.show = true;
      this.getTransaction(null);
    },
    close() {
      this.show = false;
    },
    async getTransaction(payload: PaginationInterface | null) {
      if (payload && Object.keys(payload).length > 0) {
        this.query.page = payload && payload.page;
        this.query.limit = payload && payload.limit;
      }
      this.busy = true;
      await axios
        .get("/api/wallet/transaction", {
          headers: this.$store.state.headers,
          params: {
            from: (this.query.page - 1) * this.query.limit,
            size: this.query.limit,
            start_date: dateToUnits(this.query.dateRange[0]),
            end_date: dateToUnits(this.query.dateRange[1]),
            type: this.type,
            wallet_id: 1001,
            username: this.query.search,
          },
        })
        .then((resp) => {
          this.items = resp.data.data.List || [];
          this.summary = resp.data.data.Aggs || null;
          this.paginate =
            {
              limit: resp.data.paginate.size || this.query.limit,
              page: resp.data.paginate.page || this.query.page,
              total: resp.data.paginate.total || 0,
            } || null;
        })
        .catch((error) => {
          this.$store.dispatch("swal", error);
        })
        .finally(() => {
          this.busy = false;
        });
    },

    formatMoney,
    dateToUnits,
    dateTimeFormats,
    transaction_type,
    transaction_subtype,
    number_color,
    logout,
  },
});
