
import { formatMoney, logout, number_color } from "@/plugins/GlobalFunctions";
import axios from "axios";
import Vue from "vue";

declare interface MainData {
  options_asset:
    | Array<{
        balance: number;
        currency: string;
        name: string;
        updated_at: number;
        wallet_id: number;
      }>
    | [];
  type: string;
  username: string;
  amount: number;
  note: string;
  wallet_id: number;
  sub_type: string;

  submit: boolean;
}

export default Vue.extend({
  name: "ModalWalletTransferComponent",
  created() {
    this.$root.$on(
      "modal-wallet-transfer-show",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (type: string, userInfo: any) => {
        this.open(type, userInfo);
      }
    );
  },
  data(): MainData {
    return {
      options_asset: [],
      type: "up",
      username: "",
      amount: 0,
      note: "",
      wallet_id: 1001,
      sub_type: "",

      submit: false,
    };
  },
  methods: {
    async getBalance() {
      this.submit = true;
      await axios
        .get("/api/wallet/balance", {
          headers: this.$store.state.headers,
          params: {
            username: this.username,
          },
        })
        .then((resp) => {
          let lists = resp.data.data.list || [];
          this.options_asset = lists;
        })
        .catch((error) => {
          this.$store.dispatch("swal", error);
        })
        .finally(() => {
          this.submit = false;
        });
    },
    open(type: string, userInfo: any) {
      this.sub_type = type;
      var row = JSON.parse(JSON.stringify(userInfo));
      this.username = row.username;
      this.amount = 0;
      this.note = "";
      this.submit = false;
      this.getBalance();
      this.$bvModal.show("modal-wallet-transfer-show");
    },
    close() {
      this.submit = true;
      this.$bvModal.hide("modal-wallet-transfer-show");
    },
    async onSubmit() {
      this.submit = true;
      var time = Math.floor(new Date().getTime() / 1000);
      let formData = {
        username: this.username,
        wallet_id: this.wallet_id,
        ref_code: `web-${this.username || "*"}-${time}`,
        type: "transfer",
        sub_type: this.sub_type,
        amount: this.amount,
        bonus: 0,
        is_can_minus: true,
        note: this.note,
        transaction_time: time,
      };
      await axios
        .post("/api/wallet/transaction", formData, {
          headers: this.$store.state.headers,
        })
        .then(() => {
          this.$emit("reload");
        })
        .catch((error) => {
          this.$store.dispatch("swal", error);
        })
        .finally(() => {
          this.close();
        });
    },

    formatMoney,
    number_color,
    logout,
  },
});
