
import DatePeriod from "@/components/DatePeriod.vue";
import Pagination from "@/components/Pagination.vue";
import PaginationInterface from "@/interface/Pagination";
import {
  dateTimeFormats,
  dateToUnits,
  formatMoney,
  logout,
  number_color,
  transaction_subtype,
  transaction_type,
} from "@/plugins/GlobalFunctions";
import axios from "axios";
import Vue from "vue";
import DatePicker from "vue2-datepicker";

declare interface PaginateData {
  limit: number;
  page: number;
  total: number;
}
declare interface QueryData {
  dateRange: Array<Date>;
  search: string;
  page: number;
  from: number;
  limit: number;
}
declare interface MainData {
  busy: boolean;
  query: QueryData;
  wallet_id: number;
  items: Array<{
    after_amount: number;
    amount: number;
    asset_name: string;
    asset_unit: string;
    before_amount: number;
    created_at: number;
    down_line: string;
    sub_type_name: string;
    type_name: string;
    up_line: string;
    user: string;
  }>;
  paginate: PaginateData | null;

  show: boolean;
}

export default Vue.extend({
  name: "ModalWalletTransactionDetailComponent",
  created() {
    this.$root.$on(
      "modal-wallet-transaction-detail-show",
      (username: string, dateRange: Array<Date>) => {
        this.open(username, dateRange);
      }
    );
  },
  components: {
    DatePicker,
    DatePeriod,
    Pagination,
  },
  data(): MainData {
    return {
      busy: false,
      query: {
        dateRange: [],
        search: "",
        page: 1,
        from: 0,
        limit: 10,
      },
      wallet_id: 1001,
      items: [],
      paginate: null,

      show: false,
    };
  },
  methods: {
    setNewDateRange(newDateRange: Array<Date>) {
      this.query.dateRange = newDateRange;
      this.getSumTransactionDetail(null);
    },
    open(username: string, dateRange: Array<Date>) {
      this.query.search = username;
      this.query.dateRange = dateRange;
      this.show = true;
      this.getSumTransactionDetail(null);
    },
    close() {
      this.show = false;
    },
    async getSumTransactionDetail(payload: PaginationInterface | null) {
      if (payload && Object.keys(payload).length > 0) {
        this.query.page = payload && payload.page;
        this.query.limit = payload && payload.limit;
      }
      this.busy = true;
      await axios
        .get("/api/wallet/transaction/sum-byuserv2/detail", {
          headers: this.$store.state.headers,
          params: {
            from: (this.query.page - 1) * this.query.limit,
            size: this.query.limit,
            start_date: dateToUnits(this.query.dateRange[0]),
            end_date: dateToUnits(this.query.dateRange[1]),
            wallet_id: 1001,
            username: this.query.search,
          },
        })
        .then((resp) => {
          this.items = resp.data.data || [];
          this.paginate =
            {
              limit: resp.data.paginate.size || this.query.limit,
              page: resp.data.paginate.page || this.query.page,
              total: resp.data.paginate.total || 0,
            } || null;
          this.busy = false;
        })
        .catch((error) => {
          this.$store.dispatch("swal", error);
        })
        .finally(() => {
          this.busy = false;
        });
    },

    formatMoney,
    dateToUnits,
    dateTimeFormats,
    transaction_type,
    transaction_subtype,
    number_color,
    logout,
  },
});
