
import Vue from "vue";

declare interface MainData {
  rows: number;
  perPage: number;
  currentPage: number;
  options: Array<number>;
}

export default Vue.extend({
  name: "PaginationComponent",
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  watch: {
    data() {
      this.initData();
    },
  },
  computed: {
    maxPage() {
      let max = 1;
      if (this.rows && this.perPage) {
        max =
          Math.floor(this.rows / this.perPage) +
          (this.rows % this.perPage != 0 ? 1 : 0);
      }
      return max;
    },
  },
  data(): MainData {
    return {
      rows: 0,
      perPage: 10,
      currentPage: 1,
      options: [10, 20, 50, 100],
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      if (this.data && Object.keys(this.data).length > 0) {
        let val = JSON.parse(JSON.stringify(this.data));
        this.perPage = val.limit;
        this.rows = val.total;
        var page = val.page;
        var maxPage = Math.floor(val.total / val.limit) + 1;
        this.currentPage = page > maxPage ? maxPage : page;
      }
    },
    increaseCurrentPage(val: boolean) {
      this.currentPage += val ? 1 : -1;
      this.checkCurrentPage();
    },
    checkCurrentPage() {
      this.currentPage = this.currentPage <= 0 ? 1 : this.currentPage;
      this.currentPage =
        this.currentPage >= this.maxPage ? this.maxPage : this.currentPage;
      this.callback();
    },

    callback() {
      this.$emit("callback", {
        page: this.currentPage,
        limit: this.perPage,
      });
    },
  },
});
