
import MainLayout from "@/layouts/MainLayout.vue";
import Vue from "vue";

declare interface MainData {
  busy: boolean;
}

export default Vue.extend({
  name: "IndexView",
  components: {
    MainLayout,
  },
  data(): MainData {
    return {
      busy: false,
    };
  },
});
