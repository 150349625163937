
import ChangePassword from "@/components/modal/ChangePassword.vue";
import EditProfile from "@/components/modal/EditProfile.vue";
import Member from "@/components/modal/Member.vue";
import Pagination from "@/components/Pagination.vue";
import PaginationInterface from "@/interface/Pagination";
import MainLayout from "@/layouts/MainLayout.vue";
import {
  formatMoney,
  logout,
  number_color,
  userStatusVariant,
} from "@/plugins/GlobalFunctions";
import axios from "axios";
import Swal from "sweetalert2";
import Vue from "vue";

declare interface QueryData {
  search: string;
  page: number;
  limit: number;
}
declare interface PaginateData {
  page: number;
  limit: number;
  total: number;
}
declare interface MainData {
  busy: boolean;
  query: QueryData;
  marketings:
    | Array<{
        full_name: string;
        ref_code: string | null;
      }>
    | [];
  options_status: Array<{
    text: string;
    value: boolean;
  }>;

  marketing: string | null;
  status: boolean;
  items:
    | Array<{
        username: string;
        web_id: string;
        user_type_text: string;
        status: number;
        status_text: string;
        balance: number;
        marketing: string;
        form_marketing_code: string;
        ref_code: string;
      }>
    | [];
  paginate: PaginateData | null;
}

export default Vue.extend({
  name: "UserManagementView",
  components: {
    MainLayout,
    Pagination,
    Member,
    ChangePassword,
    EditProfile,
  },
  data(): MainData {
    return {
      busy: false,
      query: {
        search: "",
        page: 1,
        limit: 10,
      },
      marketings: [],
      options_status: [
        { text: "Normal", value: false },
        { text: "Banned", value: true },
      ],
      marketing: null,
      status: false,
      items: [],
      paginate: null,
    };
  },
  mounted() {
    this.marketingsList();
    this.getMemberLists(null);
  },
  methods: {
    async marketingsList() {
      await axios
        .get("/api/user/marketings/list", {
          headers: this.$store.state.headers,
          params: {
            is_active: true,
          },
        })
        .then((resp) => {
          let marketings = resp.data.data.items || [];
          for (let index = 0; index < marketings.length; index++) {
            const element = marketings[index];
            element["full_name"] =
              element.username + "  (" + element.ref_code + ")";
          }
          marketings = [
            { full_name: "-All-", ref_code: null },
            { full_name: "-No Ref.-", ref_code: "no_ref" },
            ...marketings,
          ];
          this.marketings = marketings;
        })
        .catch((error) => {
          this.$store.dispatch("swal", error);
        });
    },
    async getMemberLists(payload: PaginationInterface | null) {
      if (payload && Object.keys(payload).length > 0) {
        this.query.page = payload && payload.page;
        this.query.limit = payload && payload.limit;
      }

      this.busy = true;
      await axios
        .get("/api/user/list", {
          headers: this.$store.state.headers,
          params: {
            search: this.query.search,
            page: this.query.page,
            limit: this.query.limit,
            user_type: "Member",
            status: this.status,
            filter_marketing: this.marketing,
          },
        })
        .then((resp) => {
          this.items = resp.data.data.items || [];
          this.paginate = resp.data.data.paginate || null;
        })
        .catch((error) => {
          this.$store.dispatch("swal", error);
        })
        .finally(() => {
          this.busy = false;
        });
    },
    createMember() {
      this.$root.$emit("modal-member-show", null);
    },
    onChangePassword(item: any) {
      this.$root.$emit("modal-change-password", item);
    },
    async onBan(userInfo: any) {
      Swal.fire({
        icon: "question",
        text: `${
          userInfo.status_text == "Normal" ? "Suspend" : "Unsuspend"
        } account "${userInfo.username}"`,
        showCancelButton: true,
        confirmButtonText: "Submit",
        cancelButtonText: "Cancel",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await axios
            .post(
              "/api/user/ban",
              {
                user_id: userInfo.id,
                is_ban: userInfo.status_text == "Normal" ? true : false,
              },
              {
                headers: this.$store.state.headers,
              }
            )
            .then(() => {
              this.getMemberLists(null);
            })
            .catch((error) => {
              this.$store.dispatch("swal", error);
            });
        }
      });
    },
    onEditProfile(item: any) {
      this.$root.$emit("modal-edit-profile", item);
    },

    formatMoney,
    userStatusVariant,
    number_color,
    logout,
  },
});
