import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

// middleware
import Auth from "@/middleware/Auth";
import NoAuth from "@/middleware/NoAuth";

// views
import GameTransactionView from "../views/game/TransactionView.vue";
import IndexView from "../views/IndexView.vue";
import LoginView from "../views/LoginView.vue";
import MarketingCommissionView from "../views/marketing/CommissionView.vue";
import MarketingManagementView from "../views/marketing/ManagementView.vue";
import MarketingTransactionView from "../views/marketing/TransactionView.vue";
import UserManagementView from "../views/user/ManagementView.vue";
import WalletTransactionView from "../views/wallet/TransactionView.vue";
import WalletTransferView from "../views/wallet/TransferView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      middleware: [NoAuth],
    },
  },
  {
    path: "/",
    name: "index",
    component: IndexView,
    meta: {
      middleware: [Auth],
    },
  },
  // user
  {
    path: "/user/management",
    name: "user-management",
    component: UserManagementView,
    meta: {
      middleware: [Auth],
    },
  },
  // wallet
  {
    path: "/wallet/transfer",
    name: "wallet-transfer",
    component: WalletTransferView,
    meta: {
      middleware: [Auth],
    },
  },
  {
    path: "/wallet/transaction",
    name: "wallet-transaction",
    component: WalletTransactionView,
    meta: {
      middleware: [Auth],
    },
  },
  // game
  {
    path: "/game/transaction",
    name: "game-transaction",
    component: GameTransactionView,
    meta: {
      middleware: [Auth],
    },
  },
  // marketing
  {
    path: "/marketing/management",
    name: "marketing-management",
    component: MarketingManagementView,
    meta: {
      middleware: [Auth],
    },
  },
  {
    path: "/marketing/transaction",
    name: "marketing-transaction",
    component: MarketingTransactionView,
    meta: {
      middleware: [Auth],
    },
  },
  {
    path: "/marketing/commission",
    name: "marketing-commission",
    component: MarketingCommissionView,
    meta: {
      middleware: [Auth],
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function nextFactory(context: any, middleware: any, index: any) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters: any) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to: any, from: any, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
