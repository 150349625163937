
import DatePeriod from "@/components/DatePeriod.vue";
import GameDetail from "@/components/modal/GameDetail.vue";
import Pagination from "@/components/Pagination.vue";
import PaginationInterface from "@/interface/Pagination";
import {
  dateTimeFormats,
  dateToUnits,
  formatMoney,
  logout,
  number_color,
} from "@/plugins/GlobalFunctions";
import axios from "axios";
import Vue from "vue";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

declare interface QueryData {
  dateRange: Array<Date>;
  search: string;
  page: number;
  from: number;
  limit: number;
}
declare interface SummaryData {
  bet: number;
  refund: number;
  win: number;
}
declare interface ItemData {
  user_name: string;
  created_at: number;
  game_name: string;
  game_list_name: string;
  transaction_id: string;
  round_id: string;
  bet_type: string;
  transaction_bet_amount: number;
  transaction_settle_amount: number;
  transaction_refund_amount: number;
  amount_before: number;
  amount_after: number;
  is_get_detail: boolean;
}
declare interface PaginateData {
  limit: number;
  page: number;
  total: number;
}
declare interface MainData {
  busy: boolean;
  query: QueryData;
  options_brands_group: Array<{
    group_name: string | null;
    group_values: Array<any>;
  }>;
  brands: Array<any> | null;
  round_id: string;
  summary: SummaryData | null;
  items: Array<ItemData>;
  paginate: PaginateData | null;

  show: boolean;
}

export default Vue.extend({
  name: "ModalGameTransactionMemberComponent",
  created() {
    this.$root.$on(
      "modal-game-transaction-member-show",
      (username: string, dateRange: Array<Date>) => {
        this.open(username, dateRange);
      }
    );
  },
  components: {
    DatePicker,
    DatePeriod,
    Pagination,
    Multiselect,
    GameDetail,
  },
  data(): MainData {
    return {
      busy: false,
      query: {
        dateRange: [],
        search: "",
        page: 1,
        from: 0,
        limit: 10,
      },
      options_brands_group: [],
      brands: null,
      round_id: "",
      summary: null,
      items: [],
      paginate: null,

      show: false,
    };
  },
  methods: {
    setNewDateRange(newDateRange: Array<Date>) {
      this.query.dateRange = newDateRange;
      this.getGameTransactionMember(null);
    },
    open(username: string, dateRange: Array<Date>) {
      this.query.search = username;
      this.query.dateRange = dateRange;
      this.gameBrand();
      this.getGameTransactionMember(null);
      this.show = true;
    },
    close() {
      this.show = false;
      this.busy = false;
    },
    async gameBrand() {
      await axios
        .get("/api/game/agent/get_brand", {
          headers: this.$store.state.headers,
        })
        .then((resp) => {
          let data = (resp.data.data && resp.data.data.game_data) || [];
          data.sort((a: any, b: any) => a.sorting - b.sorting);
          this.options_brands_group = [
            {
              group_name: "-Select all-",
              group_values: data || [],
            },
          ];
        })
        .catch((error) => {
          this.$store.dispatch("swal", error);
        });
    },
    async getGameTransactionMember(payload: PaginationInterface | null) {
      if (payload && Object.keys(payload).length > 0) {
        this.query.page = payload && payload.page;
        this.query.limit = payload && payload.limit;
      }
      this.busy = true;
      await axios
        .get("/api/report/get_transaction", {
          headers: this.$store.state.headers,
          params: {
            from: (this.query.page - 1) * this.query.limit,
            size: this.query.limit,
            start_date: dateToUnits(this.query.dateRange[0]),
            end_date: dateToUnits(this.query.dateRange[1]),
            round_id: this.round_id,
            username: this.query.search,
          },
        })
        .then((resp) => {
          this.items = resp.data.data.transaction_data || [];
          this.summary = resp.data.data.summary_data || null;
          this.paginate =
            {
              limit: resp.data.data.paginate_data.size || this.query.limit,
              page: resp.data.data.paginate_data.page || this.query.page,
              total: resp.data.data.paginate_data.total || 0,
            } || null;
        })
        .catch((error) => {
          this.$store.dispatch("swal", error);
        })
        .finally(() => {
          this.busy = false;
        });
    },
    getGameDetail(item: ItemData) {
      this.$root.$emit("modal-game-detail-show", item);
    },

    jsonString(msg: string) {
      try {
        return JSON.parse(msg || "[]");
      } catch {
        return [String(msg)];
      }
    },
    formatMoney,
    dateToUnits,
    dateTimeFormats,
    number_color,
    logout,
  },
});
