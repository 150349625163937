
import Vue from "vue";

export default Vue.extend({
  name: "DatePeriodComponent",
  methods: {
    setDate(type: string) {
      var curr = new Date();
      let start_date = [0, 0, 0];
      let end_date = [23, 59, 59];
      var firstday = null;
      var lastday = null;

      if (type == "today") {
        firstday = new Date(
          curr.getFullYear(),
          curr.getMonth(),
          curr.getDate(),
          ...start_date
        );
        lastday = new Date(
          curr.getFullYear(),
          curr.getMonth(),
          curr.getDate(),
          ...end_date
        );
      } else if (type == "yesterday") {
        firstday = new Date(
          curr.getFullYear(),
          curr.getMonth(),
          curr.getDate() - 1,
          ...start_date
        );
        lastday = new Date(
          curr.getFullYear(),
          curr.getMonth(),
          curr.getDate() - 1,
          ...end_date
        );
      } else if (type == "week") {
        var firstWeek = new Date().getDate() - new Date().getDay() + 1;
        var lastWeek = firstWeek + 6;
        curr = new Date(new Date().setDate(firstWeek));
        firstday = new Date(
          curr.getFullYear(),
          curr.getMonth(),
          curr.getDate(),
          ...start_date
        );
        curr = new Date(new Date().setDate(lastWeek));
        lastday = new Date(
          curr.getFullYear(),
          curr.getMonth(),
          curr.getDate(),
          ...end_date
        );
      } else if (type == "lastweek") {
        var firstLastweek = curr.getDate() - ((curr.getDay() + 6) % 7);
        var lastLastweek = firstLastweek + 6;

        curr = new Date(new Date().setDate(firstLastweek - 7));
        firstday = new Date(
          curr.getFullYear(),
          curr.getMonth(),
          curr.getDate(),
          ...start_date
        );
        curr = new Date(new Date().setDate(lastLastweek - 7));
        lastday = new Date(
          curr.getFullYear(),
          curr.getMonth(),
          curr.getDate(),
          ...end_date
        );
      } else if (type == "thismonth") {
        firstday = new Date(
          curr.getFullYear(),
          curr.getMonth(),
          1,
          ...start_date
        );
        lastday = new Date(
          curr.getFullYear(),
          curr.getMonth() + 1,
          0,
          ...end_date
        );
      } else if (type == "lastmonth") {
        firstday = new Date(
          curr.getFullYear(),
          curr.getMonth() - 1,
          1,
          ...start_date
        );
        lastday = new Date(curr.getFullYear(), curr.getMonth(), 0, ...end_date);
      }

      this.$emit("return", [firstday, lastday]);
    },
  },
});
