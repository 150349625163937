
import { formatMoney, logout } from "@/plugins/GlobalFunctions";
import axios from "axios";
import Vue from "vue";

declare interface MainData {
  username: string;

  user_id: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  new_password: string;
  web_id: string;

  submit: boolean;
  show: boolean;
}

export default Vue.extend({
  name: "ModalEditProfileComponent",
  created() {
    this.$root.$on("modal-edit-profile", (item: any) => {
      this.open(item);
    });
  },
  data(): MainData {
    return {
      username: "",
      user_id: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      new_password: "",
      web_id: "",

      submit: false,
      show: false,
    };
  },
  methods: {
    open(item: any) {
      this.username = item.username;
      this.user_id = item.id;
      this.first_name = item.first_name;
      this.last_name = item.last_name;
      this.phone_number = item.phone_number;
      this.new_password = "";
      this.web_id = item.web_id;
      this.show = true;
    },
    close() {
      this.show = false;
      this.submit = false;
    },
    async onSubmit() {
      this.submit = true;
      let formData = {
        first_name: this.first_name,
        last_name: this.last_name,
        new_password: this.new_password,
        phone_number: this.phone_number,
        user_id: this.user_id,
        web_id: this.web_id,
      };
      await axios
        .post("/api/user/update-profile", formData, {
          headers: this.$store.state.headers,
        })
        .then(() => {
          this.close();
          this.$emit("reload");
        })
        .catch((error) => {
          this.close();
          this.$store.dispatch("swal", error);
        })
        .finally(() => {
          this.close();
        });
    },

    formatMoney,
    logout,
  },
});
