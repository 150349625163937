import { BootstrapVue } from "bootstrap-vue";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

import "@/styles/app.scss";
import "@mdi/font/css/materialdesignicons.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
import "sweetalert2/src/sweetalert2.scss";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "vue2-datepicker/index.css";

import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_URL || "";

Vue.use(BootstrapVue);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
