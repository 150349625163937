
import WalletTransfer from "@/components/modal/WalletTransfer.vue";
import Pagination from "@/components/Pagination.vue";
import PaginationInterface from "@/interface/Pagination";
import MainLayout from "@/layouts/MainLayout.vue";
import {
  formatMoney,
  logout,
  number_color,
  userStatusVariant,
} from "@/plugins/GlobalFunctions";
import axios from "axios";
import Vue from "vue";

declare interface QueryData {
  search: string;
  page: number;
  limit: number;
}
declare interface PaginateData {
  page: number;
  limit: number;
  total: number;
}
declare interface MainData {
  busy: boolean;
  query: QueryData;
  items:
    | Array<{
        username: string;
        web_id: string;
        user_type_text: string;
        status: number;
        status_text: string;
        balance: number;
        marketing: string;
        form_marketing_code: string;
        ref_code: string;
      }>
    | [];
  paginate: PaginateData | null;
}

export default Vue.extend({
  name: "WalletTransferView",
  components: {
    MainLayout,
    Pagination,
    WalletTransfer,
  },
  data(): MainData {
    return {
      busy: false,
      query: {
        search: "",
        page: 1,
        limit: 10,
      },
      items: [],
      paginate: null,
    };
  },
  mounted() {
    this.getMemberLists(null);
  },
  methods: {
    async getMemberLists(payload: PaginationInterface | null) {
      if (payload && Object.keys(payload).length > 0) {
        this.query.page = payload && payload.page;
        this.query.limit = payload && payload.limit;
      }

      this.busy = true;
      await axios
        .get("/api/user/list", {
          headers: this.$store.state.headers,
          params: {
            search: this.query.search,
            page: this.query.page,
            limit: this.query.limit,
            user_type: "Member",
            status: false,
          },
        })
        .then((resp) => {
          this.items = resp.data.data.items || [];
          this.paginate = resp.data.data.paginate || null;
        })
        .catch((error) => {
          this.$store.dispatch("swal", error);
        })
        .finally(() => {
          this.busy = false;
        });
    },
    manageBalance(type: string, item: any) {
      this.$root.$emit("modal-wallet-transfer-show", type, item);
    },

    formatMoney,
    userStatusVariant,
    number_color,
    logout,
  },
});
