
import {
  dateTimeFormats,
  formatMoney,
  logout,
  number_color,
} from "@/plugins/GlobalFunctions";
import axios from "axios";
import Vue from "vue";

declare interface ItemData {
  id: string;
  user_id: string;
  user_name: string;
  user_game_name: string;
  game_id: string;
  game_list_id: string;
  round_id: string;
  transaction_id: string;
  api_group_key_id: string;
  transaction_time_nano: number;
  created_at: number;
  game_name: string;
  game_list_name: string;
  bet_type: string;
  transaction_bet_amount: number;
  transaction_settle_amount: number;
  transaction_refund_amount: number;
  amount_before: number;
  amount_after: number;
  is_get_detail: boolean;
}
declare interface MainData {
  imgDetailLink: string;
  item: ItemData | null;

  show: boolean;
  busy: boolean;
}

export default Vue.extend({
  name: "ModalGameDetailComponent",
  created() {
    this.$root.$on("modal-game-detail-show", (item: ItemData) => {
      this.open(item);
    });
  },
  data(): MainData {
    return {
      imgDetailLink: "",
      item: null,

      show: false,
      busy: false,
    };
  },
  methods: {
    open(item: ItemData) {
      this.imgDetailLink = "";
      this.item = item;
      this.getGameDetail();
      this.show = true;
    },
    close() {
      this.show = false;
    },
    async getGameDetail() {
      this.busy = true;
      await axios
        .get("/api/report/get_detail", {
          headers: this.$store.state.headers,
          params: {
            id: this.item && this.item.id,
            user_id: this.item && this.item.user_id,
            user_name: this.item && this.item.user_name,
            user_game_name: this.item && this.item.user_game_name,
            game_id: this.item && this.item.game_id,
            game_list_id: this.item && this.item.game_list_id,
            round_id: this.item && this.item.round_id,
            transaction_id: this.item && this.item.transaction_id,
            api_group_key_id: this.item && this.item.api_group_key_id,
            transaction_time_nano: this.item && this.item.transaction_time_nano,
          },
        })
        .then((resp) => {
          this.imgDetailLink = resp.data.data.url;
        })
        .catch((error) => {
          this.$store.dispatch("swal", error);
        })
        .finally(() => {
          this.busy = false;
        });
    },

    jsonString(msg: string) {
      try {
        return JSON.parse(msg || "[]");
      } catch {
        return [String(msg)];
      }
    },
    formatMoney,
    dateTimeFormats,
    number_color,
    logout,
  },
});
