
import DatePeriod from "@/components/DatePeriod.vue";
import GameTransactionMember from "@/components/modal/GameTransactionMember.vue";
import Pagination from "@/components/Pagination.vue";
import PaginationInterface from "@/interface/Pagination";
import {
  dateToUnits,
  formatMoney,
  logout,
  number_color,
} from "@/plugins/GlobalFunctions";
import axios from "axios";
import Vue from "vue";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

declare interface QueryData {
  dateRange: Array<Date>;
  search: string;
  page: number;
  from: number;
  limit: number;
}
declare interface SummaryData {
  bet_amount: number;
  win_amount: number;
  refund_amount: number;
  down_line_win_lose: number;
  percent_share: number;
  marketing_value: number;
}
declare interface PaginateData {
  limit: number;
  page: number;
  total: number;
}
declare interface MainData {
  busy: boolean;
  query: QueryData;
  marketing_id: string;
  options_brands_group: Array<{
    group_name: string | null;
    group_values: Array<any>;
  }>;
  brands: Array<string> | null;
  round_id: string;
  summary: SummaryData | null;
  items: Array<{
    username: string;
    marketing_code: string;
    bet_amount: number;
    down_line_win_lose: number;
    percent_share: number;
    marketing_value: number;
  }>;
  paginate: PaginateData | null;

  show: boolean;
}

export default Vue.extend({
  name: "ModalMarketingCommissionDetilComponent",
  created() {
    this.$root.$on(
      "modal-marketing-commission-detail-show",
      (marketingId: string, dateRange: Array<Date>) => {
        this.open(marketingId, dateRange);
      }
    );
  },
  components: {
    DatePicker,
    DatePeriod,
    Pagination,
    Multiselect,
    GameTransactionMember,
  },
  data(): MainData {
    return {
      busy: false,
      query: {
        dateRange: [],
        search: "",
        page: 1,
        from: 0,
        limit: 10,
      },
      marketing_id: "",
      options_brands_group: [],
      brands: null,
      round_id: "",
      summary: null,
      items: [],
      paginate: null,

      show: false,
    };
  },
  methods: {
    setNewDateRange(newDateRange: Array<Date>) {
      this.query.dateRange = newDateRange;
      this.getMarketingTransactionDetail(null);
    },
    open(marketingId: string, dateRange: Array<Date>) {
      this.marketing_id = marketingId;
      this.query.dateRange = dateRange;
      this.gameBrand();
      this.getMarketingTransactionDetail(null);
      this.show = true;
    },
    close() {
      this.show = false;
    },
    async gameBrand() {
      await axios
        .get("/api/game/agent/get_brand", {
          headers: this.$store.state.headers,
        })
        .then((resp) => {
          let data = (resp.data.data && resp.data.data.game_data) || [];
          data.sort((a: any, b: any) => a.sorting - b.sorting);
          this.options_brands_group = [
            {
              group_name: "-Select all-",
              group_values: data || [],
            },
          ];
        })
        .catch((error) => {
          this.$store.dispatch("swal", error);
        });
    },
    async getMarketingTransactionDetail(payload: PaginationInterface | null) {
      if (payload && Object.keys(payload).length > 0) {
        this.query.page = payload && payload.page;
        this.query.limit = payload && payload.limit;
      }
      let array_game_id =
        (this.brands &&
          this.brands.map((e: any) => {
            return e.id;
          })) ||
        null;
      this.busy = true;
      await axios
        .get("/api/report/get_transaction_sum_by_user", {
          headers: this.$store.state.headers,
          params: {
            from: (this.query.page - 1) * this.query.limit,
            size: this.query.limit,
            start_date: dateToUnits(this.query.dateRange[0]),
            end_date: dateToUnits(this.query.dateRange[1]),
            array_game_id: array_game_id,
            search: this.query.search,
            marketing_id: this.marketing_id,
          },
        })
        .then((resp) => {
          this.items = resp.data.data.transaction_sum_by_user_data || [];
          this.summary = resp.data.data.summary_data || null;
          this.paginate =
            {
              limit: resp.data.data.paginate_data.size || this.query.limit,
              page: resp.data.data.paginate_data.page || this.query.page,
              total: resp.data.data.paginate_data.total || 0,
            } || null;
        })
        .catch((error) => {
          this.$store.dispatch("swal", error);
        })
        .finally(() => {
          this.busy = false;
        });
    },
    showGameTransactionMember(username: string, dateRange: Array<Date>) {
      this.$root.$emit(
        "modal-game-transaction-member-show",
        username,
        dateRange
      );
    },

    formatMoney,
    dateToUnits,
    number_color,
    logout,
  },
});
