
import DatePeriod from "@/components/DatePeriod.vue";
import Pagination from "@/components/Pagination.vue";
import PaginationInterface from "@/interface/Pagination";
import MainLayout from "@/layouts/MainLayout.vue";
import {
  dateToUnits,
  formatMoney,
  logout,
  number_color,
} from "@/plugins/GlobalFunctions";
import axios from "axios";
import Vue from "vue";
import DatePicker from "vue2-datepicker";

declare interface PaginateData {
  limit: number;
  page: number;
  total: number;
}
declare interface QueryData {
  dateRange: Array<Date>;
  search: string;
  page: number;
  from: number;
  limit: number;
}
declare interface SummaryData {
  deposit: number;
  withdraw: number;
}
declare interface MainData {
  busy: boolean;
  query: QueryData;
  items:
    | Array<{
        username: string;
        code: string;
        summary: SummaryData;
      }>
    | [];
  paginate: PaginateData | null;
}
export default Vue.extend({
  name: "MarketingTransactionView",
  components: {
    MainLayout,
    DatePeriod,
    Pagination,
    DatePicker,
  },
  data(): MainData {
    return {
      busy: false,
      query: {
        dateRange: [],
        search: "",
        page: 1,
        from: 0,
        limit: 10,
      },
      items: [],
      paginate: null,
    };
  },
  mounted() {
    this.setDateRange();
  },
  methods: {
    setNewDateRange(newDateRange: Array<Date>) {
      this.query.dateRange = newDateRange;
      this.getMarketingTransactionLists(null);
    },
    setDateRange() {
      let start_date = [0, 0, 0];
      let end_date = [23, 59, 59];
      var curr = new Date();
      var firstday = new Date(
        curr.getFullYear(),
        curr.getMonth(),
        curr.getDate(),
        ...start_date
      );
      var lastday = new Date(
        curr.getFullYear(),
        curr.getMonth(),
        curr.getDate(),
        ...end_date
      );
      this.query.dateRange = [firstday, lastday];
      this.getMarketingTransactionLists(null);
    },
    async getMarketingTransactionLists(payload: PaginationInterface | null) {
      if (payload && Object.keys(payload).length > 0) {
        this.query.page = payload && payload.page;
        this.query.limit = payload && payload.limit;
      }
      this.busy = true;
      await axios
        .get("/api/wallet/transaction/marketingv2", {
          headers: this.$store.state.headers,
          params: {
            search: this.query.search,
            from: (this.query.page - 1) * this.query.limit,
            size: this.query.limit,
            start_date: dateToUnits(this.query.dateRange[0]),
            end_date: dateToUnits(this.query.dateRange[1]),
          },
        })
        .then((resp) => {
          this.items = resp.data.data || [];
          this.paginate =
            {
              limit: resp.data.paginate.size || this.query.limit,
              page: resp.data.paginate.page || this.query.page,
              total: resp.data.paginate.total || 0,
            } || null;
        })
        .catch((error) => {
          this.$store.dispatch("swal", error);
        })
        .finally(() => {
          this.busy = false;
        });
    },

    formatMoney,
    dateToUnits,
    number_color,
    logout,
  },
});
